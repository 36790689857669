<template>
  <v-container fluid>
    <v-row>
      <v-col
        v-if="
          currentLayerNode &&
            !currentLayerNode.is3DFile
        "
        cols="auto"
      >
        <Style2DEditor :layer-node="currentLayerNode">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              class="primary--text text-capitalize"
              fab
              large
              v-on="on"
            >
              <v-row
                class="flex-column"
                no-gutters
              >
                <v-col>
                  <v-icon>mdi-palette</v-icon>
                </v-col>
                <v-col>
                  <span style="font-size: 0.625rem;">Style 2D</span>
                </v-col>
              </v-row>
            </v-btn>
          </template>
        </Style2DEditor>
      </v-col>
      <v-col
        v-if="
          currentLayerNode &&
            !currentLayerNode.is3DFile &&
            [
              SIM_MODEL.MODFLOW,
              SIM_MODEL.FEMWATER_MESH
            ].includes(simulationModel)
        "
        cols="auto"
      >
        <ModflowParametersMenu>
          <template v-slot:activator="{ on, attrs, edit }">
            <v-badge
              :value="edit"
              bordered
              icon="mdi-pencil"
              color="secondary"
              overlap
            >
              <v-btn
                v-bind="attrs"
                class="primary--text text-capitalize"
                fab
                large
                v-on="on"
              >
                <v-row
                  class="flex-column"
                  no-gutters
                >
                  <v-col>
                    <v-icon>mdi-tune</v-icon>
                  </v-col>
                  <v-col>
                    <span style="font-size: 0.625rem;">{{ $t('simulation_parameter_abbreviation') }}</span>
                  </v-col>
                </v-row>
              </v-btn>
            </v-badge>
          </template>
        </ModflowParametersMenu>
      </v-col>

      <v-col
        v-if="
          currentLayerNode &&
            currentLayerNode.hasLegends
        "
        cols="auto"
      >
        <LegendsDialog
          :layer-node="currentLayerNode"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              class="primary--text text-capitalize"
              fab
              large
              v-on="on"
            >
              <v-row
                class="flex-column"
                no-gutters
              >
                <v-col>
                  <v-icon>mdi-invert-colors</v-icon>
                </v-col>
                <v-col>
                  <span style="font-size: 0.625rem;">Legends</span>
                </v-col>
              </v-row>
            </v-btn>
          </template>
        </LegendsDialog>
      </v-col>

      <v-col
        v-if="isPostprocessModel"
        v-show="canSlice"
        cols="auto"
      >
        <Slice>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              class="primary--text text-capitalize"
              fab
              large
              :loading="isSlicing"
              v-on="on"
            >
              <v-row
                class="flex-column"
                no-gutters
              >
                <v-col>
                  <v-icon>mdi-segment</v-icon>
                </v-col>
                <v-col>
                  <span style="font-size: 0.625rem;">Slice</span>
                </v-col>
              </v-row>
            </v-btn>
          </template>
        </Slice>
      </v-col>

      <v-col cols="auto">
        <MassbalanceChart
          v-if="
            (isMapModel || isPostprocessModel) &&
              canMbSlice &&
              simulationModel === SIM_MODEL.MASSBALANCE
          "
        >
          <template v-slot:activator="{ on, attrs, loading }">
            <v-btn
              v-bind="attrs"
              class="primary--text text-capitalize"
              fab
              large
              :loading="loading"
              v-on="on"
            >
              <v-row
                class="flex-column"
                no-gutters
              >
                <v-col>
                  <v-icon>mdi-waves</v-icon>
                </v-col>
                <v-col>
                  <span style="font-size: 0.625rem;">水平衡<br>圖表</span>
                </v-col>
              </v-row>
            </v-btn>
          </template>
        </MassbalanceChart>
      </v-col>

      <v-col
        v-if="
          (isMapModel || isPostprocessModel) &&
            canMbSlice &&
            simulationModel === SIM_MODEL.MASSBALANCE
        "
        cols="auto"
      >
        <MassbalanceSlice>
          <template v-slot:activator="{ on, attrs, loading }">
            <v-btn
              v-bind="attrs"
              class="primary--text text-capitalize"
              fab
              large
              :loading="loading"
              v-on="on"
            >
              <v-row
                class="flex-column"
                no-gutters
              >
                <v-col>
                  <v-icon>mdi-waves-arrow-right</v-icon>
                </v-col>
                <v-col>
                  <span style="font-size: 0.625rem;">水平衡<br>畫線</span>
                </v-col>
              </v-row>
            </v-btn>
          </template>
        </MassbalanceSlice>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Style2DEditor from './Style2D/Style2DEditor'
import ModflowParametersMenu from '@/components/Simulation/Modflow/Parameters/ModflowParametersMenu'
import LegendsDialog from './Legends/LegendsDialog'
import Slice from './Slice'
import MassbalanceChart from '@/components/Simulation/MassbalanceCharts/MassbalanceChart'
import MassbalanceSlice from '@/components/Simulation/MassbalanceCharts/MassbalanceSlice'
import { SIM_MODEL } from '@/models'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'LayerFunctions',

  components: {
    Style2DEditor,
    ModflowParametersMenu,
    LegendsDialog,
    Slice,
    MassbalanceChart,
    MassbalanceSlice
  },

  data: () => ({
    SIM_MODEL
  }),

  computed: {
    ...mapState({
      currentLayerNode: state => state.map.currentLayerNode,
      simulationModel: state => state.simulation.model
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute',
      getRoleByResourceId: 'projects/getRoleByResourceId',
      isMapModelByRoute: 'map/isMapModelByRoute',
      isPostprocessModelByRoute: 'map/isPostprocessModelByRoute'
    }),

    isMapModel() {
      return this.isMapModelByRoute(this.$route)
    },
    isPostprocessModel() {
      return this.isPostprocessModelByRoute(this.$route)
    },
    project() {
      return this.getProjectByRoute(this.$route)
    },
    canMassbalanceSlice() {
      return (this.isMapModel || this.isPostprocessModel) &&
        this.currentLayerNode.is3DFile
    },
    canSave() {
      return !this.isMapModel &&
      this.currentLayerNode?.stagingFile
    },
    canSlice() {
      return this.isPostprocessModel &&
       this.currentLayerNode?.is3DFile &&
       this.currentLayerNode.settings?.parentVtk &&
       this.currentLayerNode.sourceMesh
    },
    canMbSlice() {
      return !!this.currentLayerNode?.settings?.massbalance?.datMaplayerResourceUuid
    },
    isSlicing() {
      return this.currentLayerNode?.isSlicing
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
