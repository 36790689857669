<template>
  <v-row
    v-if="parameter"
    :class="{
      'row--disabled': isDisabled,
      ...parameter.class
    }"
    no-gutters
    align="center"
    :tag="parameter.tag || tag || 'div'"
  >
    <!-- legend for fieldset -->
    <ParameterInput v-if="hasLegend" :parameter="parameter.legend" tag="legend" />

    <!-- show text methods -->
    <v-checkbox
      v-if="hasChecked"
      v-model="parameter.apply"
      hide-details
      dense
      :disabled="isDisabledCheckbox"
    />
    <v-col
      v-if="showTextCol"
      class="text-col"
      :class="{
        'text-col--disabled': isDisabled,
        'text-col--with-input': !hasNoInput
      }"
      cols="auto"
    >
      {{ parameter.text }}
    </v-col>
    <template v-else>
      <!-- for legend case -->
      {{ parameter.text || '' }}
    </template>

    <!-- radio-group case -->
    <v-radio-group
      v-if="isRadio"
      v-model="parameter.case"
      :class="{
        'mt-n1': true,
        'pt-0': true,
        shrink: true,
        ...parameter.cases.class
      }"
      dense
      hide-details="auto"
      :disabled="isDisabled"
    >
      <v-row
        v-for="(caseParameter, objKey) in radioOptions"
        :key="objKey"
        class="flex-nowrap shrink"
        no-gutters
        align="center"
      >
        <v-col
          cols="auto"
          :class="{
            'align-self-start': caseParameter.children
          }"
        >
          <v-radio
            :value="objKey"
            :disabled="caseParameter.disabled || caseParameter.rootDisabled"
          />
        </v-col>
        <v-col class="ml-0">
          <ParameterInput :parameter="caseParameter" />
        </v-col>
      </v-row>
    </v-radio-group>

    <!-- input value -->
    <v-text-field
      v-else-if="hasValue"
      v-model="value"
      outlined
      hide-details="auto"
      :disabled="isDisabled"
      @blur="onBlur"
    />

    <!-- unit -->
    <v-col
      v-if="hasUnit"
      class="unit-col"
      :class="{ 'unit-col--disabled': isDisabled }"
      cols="auto"
      v-text="parameter.unit"
    />

    <v-col v-if="hasTargetOptions">
      <v-select
        v-model="parameter.target"
        :items="parameter.targetOptions"
        hide-details="auto"
        dense
        outlined
        flast
        :disabled="isDisabled"
      />
    </v-col>

    <!-- show button -->
    <slot
      name="activator"
      :attrs="attrsActivator"
      :disabled="isDisabled"
      :parameter="parameter"
    >
      <v-btn v-if="isButton" v-bind="attrsActivator" :disabled="isDisabled">
        {{ parameter.buttonText }}
      </v-btn>
    </slot>

    <!-- additions -->
    <v-row
      v-if="hasAdditions"
      class="addition-row col-auto align-self-start"
      :class="{
        grow: !isRadio,
        shrink: isRadio
      }"
      no-gutters
    >
      <ParameterInput
        v-for="(addition, objKey) in parameter.additions"
        :key="objKey"
        class="shrink"
        :parameter="addition"
      />
    </v-row>

    <!-- nested -->
    <template v-if="hasChildren">
      <ParameterInput
        v-for="(childParameter, objKey) in parameter.children"
        :key="objKey"
        :class="{
          'row--child': parameter.tag !== 'fieldset' || objKey === 'random'
        }"
        :parameter="childParameter"
      />
    </template>
  </v-row>
</template>

<script>
const attrsActivator = {
  color: '#14a83b'
}

export default {
  name: 'ParameterInput',

  props: {
    parameter: {
      type: Object,
      default: null
      // parameter = {
      //   class: v-bind:class for v-row(wrapper) // optional
      //   tag: String, // optional, v-row的tag可設定成fieldset或legend
      //   text: String, // optional
      //   value: String | Number, // optional
      //   buttonText: String, // optional, 設為 v-btn
      //   case: String | Number, // optional, 設為 v-radio-group須配合cases
      //   apply: Boolean, // optional, 顯示v-checkbox
      //   disabled: Boolean, // optional, 禁用此parameter
      //   rootDisabled: Boolean, // optional, 爺/父層是否禁用
      //   unit: String, // optional
      //   additions: Objects of parameter  // optional, 添加在v-row內右邊的東西
      //   children: Objects of parameter  // optional
      //   cases: Objects of parameter  // optional, v-radio-group的options
      // }
    },
    tag: {
      // 額外設定最外層 v-row的html tag, 優先權小於 parameter.tag
      type: String,
      default: null
    }
  },

  data: vm => ({
    oriValue: vm.parameter.value,
    attrsActivator
  }),

  computed: {
    value: {
      get() {
        return this.parameter.value
        // return parseFloat(this.parameter.value) || this.parameter.value;
      },
      set(newVal) {
        this.parameter.value = newVal
      }
    },
    isDisabled() {
      return (
        this.parameter.disabled ||
        this.parameter.rootDisabled ||
        this.parameter.apply === false
      )
    },
    isDisabledCheckbox() {
      return this.parameter.disabled || this.parameter.rootDisabled
    },
    isButton() {
      return typeof this.parameter?.buttonText === 'string'
    },
    isRadio() {
      return Object.prototype.hasOwnProperty.call(this.parameter, 'case')
    },
    radioOptions() {
      const cases = this.parameter?.cases || {}

      return Object.fromEntries(
        Object.keys(cases)
          .filter(key => /^[A-Z]/.test(key))
          .map(key => [key, cases[key]])
      )
      // return this.parameter?.cases || {};
    },
    hasLegend() {
      return (
        this.parameter?.tag === 'fieldset' &&
        typeof this.parameter?.legend === 'object'
      )
    },
    hasNoInput() {
      return !this.hasValue
    },
    showTextCol() {
      return this.parameter.text && this.parameter.tag !== 'legend'
    },
    hasChecked() {
      return (
        typeof this.parameter?.apply === 'boolean' &&
        this.parameter.tag !== 'fieldset'
      )
    },
    hasValue() {
      return (
        Object.prototype.hasOwnProperty.call(this.parameter, 'value') &&
        !this.isButton
      )
    },
    hasUnit() {
      return Object.prototype.hasOwnProperty.call(this.parameter, 'unit')
    },
    hasAdditions() {
      return typeof this.parameter?.additions === 'object'
    },
    hasChildren() {
      return typeof this.parameter?.children === 'object'
    },
    hasTargetOptions() {
      return Array.isArray(this.parameter.targetOptions)
    },
    typeInput() {
      return typeof this.parameter?.value === 'number' ? 'number' : 'text'
    }
  },

  methods: {
    onBlur() {
      const newVal = parseFloat(this.value)

      if (isNaN(newVal) || newVal == null || newVal === '') {
        this.value = this.oriValue

        return
      }

      this.value = `${newVal}`
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input {
    font-size: 1rem;
  }
}
</style>
