<template>
  <v-dialog
    v-model="dialog"
    max-width="870"
    content-class="modflow-model-dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs">
        <!-- <v-btn class="dialog-activator" v-bind="attrs" v-on="on">
          屬性設定
        </v-btn> -->
      </slot>
    </template>

    <v-card min-height="600">
      <v-card-title>
        <v-row class="px-3 my-0 py-1" align="center">
          <v-col class="apply-divider pa-0 mb-3 mr-11">
            <template v-if="editModelFileNode">
              <template
                v-for="(breadcrumb, iBreadcrumb) in editModelFileNode.breadcrumbs"
              >
                <span
                  :key="iBreadcrumb"
                  class="text-no-wrap"
                  style="font-size: 0.9rem;"
                >{{ breadcrumb }}</span>
                <v-icon
                  v-if="iBreadcrumb < editModelFileNode.breadcrumbs.length - 1"
                  :key="`${iBreadcrumb}-divder`"
                  color="primary"
                  small
                >
                  mdi-chevron-right
                </v-icon>
              </template>
            </template>
            <span
              v-else
              class="text-h6"
            >{{ $t('new_file') }}</span>
          </v-col>
          <v-col
            cols="auto"
            style="width: 16rem;"
          >
            <v-select
              v-model="selectSetting"
              :items="settingOptions"
              hide-details="auto"
              outlined
              dense
            />
          </v-col>
          <v-col cols="auto">
            <SaveAsDialog
              v-if="!isEdit"
              :file-content="modflowModel"
              :edit-file-node.sync="editModelFileNode"
            >
              <template v-slot:activator="{ on, attrs}">
                <v-btn
                  v-bind="attrs"
                  class="apply-btn"
                  color="primary"
                  dark
                  v-on="on"
                >
                  {{ $t('save_as_new_file') }}
                </v-btn>
              </template>
            </SaveAsDialog>

            <v-tooltip
              v-else
              top
              nudge-top="-15px"
              :disabled="roleEditModelFileNode.update"
            >
              <span>{{ $t('permission_denied') }}</span>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn
                    class="apply-btn"
                    color="primary"
                    :loading="isLoading"
                    :disabled="!roleEditModelFileNode.update"
                    @click="save"
                  >
                    {{ $t('save') }}
                  </v-btn>
                </div>
              </template>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <component
          :is="currentParametersComponent"
          v-if="editParameters"
          :parameters="editParameters"
        />
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-spacer />

        <v-btn
          class="action-btn"
          rounded
          :disabled="isLoading"
          @click="cancel"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          v-if="isEdit"
          class="action-btn ml-12"
          color="secondary"
          rounded
          :disabled="isLoading"
          @click="close"
        >
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ModflowModelForm from './ModflowModelForm'
import FemwaterMeshModelForm from './FemwaterMeshModelForm'
import Point from '@/components/Simulation/Modflow/Parameters/Point/PointTabs'
import LineString from '@/components/Simulation/Modflow/Parameters/LineString/LineStringTabs'
import Polygon from '@/components/Simulation/Modflow/Parameters/Polygon/PolygonTabs'
import SaveAsDialog from '@/components/Simulation/SaveAsDialog.vue'

import { SIM_MODEL } from '@/models'
import { roleRoot } from '@/store/modules/files/files'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'ModflowModelDialog',

  components: {
    ModflowModelForm,
    FemwaterMeshModelForm,
    'Parameters-Point': Point,
    'Parameters-LineString': LineString,
    'Parameters-Polygon': Polygon,
    SaveAsDialog
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data: vm => ({
    show: false,
    isLoading: false,
    selectSetting: 'model'
  }),

  computed: {
    ...mapState({
      model: state => state.simulation.model,
      currentLayerNode: state => state.map.currentLayerNode,
      modflowModel: state => state.simulation.modflow.modflowModel
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute',
      getRoleByResourceId: 'projects/getRoleByResourceId'
    }),

    dialog: {
      get() {
        return this.show
      },
      set(newVal) {
        this.show = newVal
        this.$emit('input', newVal)
      }
    },
    editModelFileNode: {
      get() {
        return this.$store.state.simulation.editModelFileNode
      },
      set(newVal) {
        this.$store.commit('simulation/setState', { editModelFileNode: newVal })
      }
    },

    project() {
      return this.getProjectByRoute(this.$route)
    },
    isProjectOwner() {
      return this.project?.isOwner
    },
    isEdit() {
      return !!this.editModelFileNode
    },
    editParameters() {
      if (this.selectSetting === 'model') {
        const { MODFLOW, FEMWATER_MESH } = SIM_MODEL

        switch (this.model) {
          case MODFLOW:
            return this.modflowModel?.MODFLOW?.Grid
          case FEMWATER_MESH:
            return this.modflowModel?.FEMWATER?.Mesh
        }
      }

      return this.modflowModel.template[this.selectSetting]
    },
    currentParametersComponent() {
      const { MODFLOW, FEMWATER_MESH } = SIM_MODEL
      let component = ''
      switch (this.selectSetting) {
        case 'model':
          switch (this.model) {
            case MODFLOW: component = 'ModflowModelForm'
              break
            case FEMWATER_MESH: component = 'FemwaterMeshModelForm'
              break
          }
          break
        case 'point':
          component = 'Parameters-Point'
          break
        case 'linestring':
          component = 'Parameters-LineString'
          break
        case 'polygon':
          component = 'Parameters-Polygon'
          break
      }

      return component
    },
    settingOptions() {
      return [
        {
          text: this.$t('model_settings'),
          value: 'model'
        },
        {
          text: this.$t('global_well'),
          value: 'point'
        },
        {
          text: this.$t('global_line'),
          value: 'linestring'
        },
        {
          text: this.$t('global_area'),
          value: 'polygon'
        }
      ]
    },
    roleEditModelFileNode() {
      if (!this.isEdit || this.isProjectOwner) {
        return roleRoot
      }

      return this.getRoleByResourceId(this.project, this.editModelFileNode.uuid) || {}
    }
  },

  watch: {
    value: {
      handler(newVal) {
        this.show = newVal
      },
      immediate: true
    },
    dialog: {
      handler(newVal) {
        if (newVal) {
          this.init()
        }
      },
      immediate: true
    }
  },

  methods: {
    init() {
      this.selectSetting = 'model'

      if (this.isEdit) {
        this.$store.dispatch('simulation/modflow/editModel', {
          editFileNode: this.editModelFileNode
        })

        return
      }

      this.$store.dispatch('simulation/modflow/createNewModel')
    },
    close() {
      this.dialog = false
    },
    cancel() {
      this.editModelFileNode = null
      this.close()
    },
    save() {
      this.isLoading = true
      return this.$store.dispatch('files/updateMaplayerData', {
        project: this.project,
        fileNode: this.editModelFileNode,
        jsonContent: this.modflowModel
      })
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('success_edited')
          })
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', { content: error })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss">
.v-dialog.modflow-model-dialog {
  display: flex;

  .v-input {
    font-size: 1rem;
  }
}
</style>

<style lang="scss" scoped>
.v-btn.v-btn.action-btn {
  padding: 0.286em 1.743em;
  height: unset;
  font-size: 0.875rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;
}

.v-dialog {
  .v-card {
    display: flex;
    flex-direction: column;

    .v-card__text {
      overflow-y: auto;
      color: #000;
      flex-grow: 1;
    }

    .v-card__actions {
      flex-grow: 0;
    }

    .v-btn:focus {
      outline: none;
    }

    .apply-divider {
      // height: 0;
      border-bottom: solid 2px $color-primary;
    }

    .apply-btn {
      font-size: 1rem;

      // border: solid 1px $color-primary;
      border-radius: 6px;
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
