<template>
  <div>
    <slot
      v-if="!hasMbSliceData"
      name="activator"
      :on="vOn"
      :loading="isLoading"
    >
      <v-btn
        :loading="isLoading"
        v-on="vOn"
      >
        {{ $t('massbalance_draw_line') }}
      </v-btn>
    </slot>
    <MassbalanceSliceChartDialog
      v-else
      v-model="dialog"
      :mb-slice-data="mbSliceData"
      @redraw="redraw"
    >
      <template v-slot:activator="{ on, attrs }">
        <slot name="activator" :on="on" :attrs="attrs" :loading="isLoading">
          <v-btn
            v-bind="attrs"
            :loading="isLoading"
            v-on="on"
          >
            {{ $t('massbalance_draw_line') }}
          </v-btn>
        </slot>
      </template>
    </MassbalanceSliceChartDialog>
  </div>
</template>

<script>
import MassbalanceSliceChartDialog from './MassbalanceSliceChartDialog.vue'

import MapboxDraw from '@mapbox/mapbox-gl-draw'

import { mapGetters, mapState } from 'vuex'

const oriDragMove = MapboxDraw.modes.simple_select.dragMove

export default {
  name: 'MassbalanceSlice',

  components: {
    MassbalanceSliceChartDialog
  },

  data: () => ({
    drawer: null,
    dialog: false
  }),

  computed: {
    ...mapState({
      currentLayerNode: state => state.map.currentLayerNode
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute',
      map: 'map/map',
      layerNodes: 'map/layerNodes',
      isPostprocessModelByRoute: 'map/isPostprocessModelByRoute'
    }),

    vOn() {
      return { click: this.drawLineString }
    },
    isPostprocessModel() {
      return this.isPostprocessModelByRoute(this.$route)
    },
    project() {
      return this.getProjectByRoute(this.$route)
    },
    mbSliceData() {
      return this.currentLayerNode?.mbSliceStagingFile?.fileContent
    },
    hasMbSliceData() {
      return !!this.mbSliceData
    },
    isLoading() {
      if (!this.currentLayerNode?.wsMassbalanceSlice) {
        return false
      }

      return this.currentLayerNode.wsMassbalanceSlice.isRunning
    }
  },

  watch: {
    currentLayerNode: {
      handler(newVal) {
        this.onChangeLayerNode(newVal)
      }
    },
    '$route.name': {
      handler(newVal, oldVal) {
        if (
          oldVal === 'MapPostprocess' &&
           this.drawer
        ) {
          this.removeDrawer()
        }
      }
    }
  },

  methods: {
    async drawLineString() {
      if (!this.isPostprocessModel) {
        await this.$router.push({
          name: 'MapPostprocess',
          params: this.$route.params,
          query: this.$route.query
        })
      }

      if (this.drawer) {
        this.drawer.changeMode('draw_line_string')

        return
      }

      // Tricy: 禁止改變已經畫好的線段
      MapboxDraw.modes.simple_select.dragMove = function() {}

      this.drawer = new MapboxDraw({
        displayControlsDefault: false,
        defaultMode: 'draw_line_string',
        controls: {
          point: false,
          line_string: false,
          polygon: false,
          trash: false,
          combine_features: false,
          uncombine_features: false
        }
      })
      this.map.addControl(this.drawer, 'bottom-right')
      this.map.on('draw.create', this.onDrawCreate)
      this.map.on('draw.update', this.onDrawUpdate)
      this.map.on('draw.delete', this.onDrawDelete)
      this.map.on('draw.modechange', this.onModechange)
    },
    removeDrawer() {
      // Tricy: 禁止改變已經畫好的線段
      MapboxDraw.modes.simple_select.dragMove = oriDragMove

      this.map.removeControl(this.drawer)
      this.map.off('draw.create', this.onDrawCreate)
      this.map.off('draw.update', this.onDrawUpdate)
      this.map.off('draw.delete', this.onDrawDelete)
      this.drawer = null

      this.layerNodes.forEach(layerNode => layerNode.setProperties({
        massbalanceStringline: null,
        mbSliceStagingFile: null
      }))
    },
    redraw() {
      if (!this.drawer) {
        this.drawLineString()

        return
      }

      this.drawer.deleteAll()

      this.drawer.changeMode('draw_line_string')

      this.dialog = false
    },
    onModechange(e) {
      if (
        e.mode === 'simple_select' &&
        !!this?.currentLayerNode?.massbalanceStringline

      ) {
        this.onChangeLayerNode(this.currentLayerNode)
      }
    },
    onChangeLayerNode(layerNode) {
      if (!this.drawer) {
        return
      }

      this.drawer.deleteAll()

      if (layerNode?.massbalanceStringline) {
        this.drawer.add(layerNode.massbalanceStringline)
      }
    },
    onDrawCreate(e) {
      const featureCollection = this.drawer.getAll()
      this.currentLayerNode.setProperties({
        massbalanceStringline: featureCollection
      })

      this.startSimulation()
    },
    onDrawUpdate(e) {
      const featureCollection = this.drawer.getAll()

      this.currentLayerNode.setProperties({
        massbalanceStringline: featureCollection
      })
    },
    onDrawDelete(e) {
      this.currentLayerNode.setProperties({
        massbalanceStringline: null
      })
    },
    startSimulation() {
      this.$store.dispatch('simulation/massbalance/startSlice', {
        project: this.project,
        layerNode: this.currentLayerNode
      })
        .then(() => {
          this.dialog = true
        })
        .catch(error => {
          this.removeDrawer()
          this.$store.dispatch('snackbar/showError', { content: error })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer-options-snackbar.v-snack {
  z-index: 100;

  ::v-deep {
    .v-snack__wrapper {
      min-height: unset;

      .v-snack__content {
        padding-top: 6px;
        padding-bottom: 6px;
      }
    }
  }
}

</style>
