<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs">
        <v-btn
          v-bind="attrs"
          color="primary"
          v-on="on"
        >
          <span
            v-if="isRoot"
          >{{ $t('page.files.add_folder_title') }}</span>
          <span
            v-else
          >{{ $t('page.files.add_mapset_title') }}</span>
        </v-btn>
      </slot>
    </template>
    <v-form
      ref="form"
      :disabled="isLoading"
      @submit.prevent="submit"
    >
      <v-card class="action-dialog-card">
        <v-card-text>
          <v-row
            class="
              draggable
              grey--text
              text--darken-4
            "
            no-gutters
          >
            <span v-if="isRoot">{{ $t('page.files.add_folder_title') }}</span>
            <i18n v-else path="page.files.add_mapset_content" tag="span">
              <template v-slot:foldername>
                <span class="primary--text" style="margin-left: 0.5em;">{{ parent.name }}</span>
              </template>
            </i18n>
          </v-row>
          <v-row no-gutters>
            <v-text-field
              v-model="name"
              :placeholder="
                isRoot
                  ? $t('folder_name')
                  : $t('mapset_name')
              "
              autofocus
            />
          </v-row>
        </v-card-text>

        <v-card-actions class="pb-0">
          <v-spacer />
          <v-btn
            class="action-btn"
            color="primary"
            rounded
            :loading="isLoading"
            @click="submit"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            class="action-btn ml-3"
            rounded
            :disabled="isLoading"
            @click="cancel"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { FileNode } from '@/models'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'AddFolderDialog',

  props: {
    parent: { // 沒有parent代表是為root增加folder
      type: FileNode,
      default: null
    },
    storeModule: { // 使用的store module, files | globalFiles
      type: String,
      default: 'files'
    }
  },

  data: () => ({
    dialog: false,
    isLoading: false,
    name: ''
  }),

  computed: {
    ...mapState({
      fileTree: state => state?.files?.fileTree
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute'
    }),

    project() {
      return this.getProjectByRoute(this.$route)
    },
    isRoot() {
      return !this.parent || this.parent === this.fileTree?.root
    }
  },

  watch: {
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
      }
    }
  },

  methods: {
    init() {
      this.name = ''
    },
    cancel() {
      this.dialog = false

      this.init()
    },
    fetchProject() {
      return this.$store.dispatch('projects/fetchProject', this.$route.params)
    },
    async submit() {
      this.isLoading = true
      return this.$store.dispatch(`${this.storeModule}/addFolder`, {
        name: this.name,
        parent: this.parent,
        project: this.project
      })
        .then(newFileNode => {
          this.$store.dispatch(`${this.storeModule}/activeFileNode`, {
            project: this.project,
            fileNode: newFileNode
          })
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('success_folder_created')
          })

          this.fetchProject()

          this.cancel()
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.action-dialog-card {
  padding: 24px 2rem;

  .v-card__subtitle,
  .v-card__text {
    font-size: 1rem;
  }
}

.v-btn.v-btn.action-btn {
  padding: 0.286em 1.743em;
  height: unset;
  font-size: 0.875rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;
}
</style>
