<template>
  <v-dialog
    v-model="dialog"
    max-width="700"
    :retain-focus="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs" />
    </template>

    <v-form ref="form" v-model="isValid">
      <v-card min-height="200">
        <v-card-title
          class="
          draggable
          bg-ease
          white--text
          px-5
          py-1
        "
          style="font-size: 1rem;"
        >
          <span>
            {{ $t('simulation_parameter_abbreviation') }}
          </span>
          <v-btn
            class="ml-auto"
            icon
            dark
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-3">
          <template v-if="!isEdit">
            <v-input
              :class="{'mb-6': !formData.pathFileNode}"
              :value="formData.pathFileNode"
              :rules="rulesPathFileNode"
              hide-details="auto"
            >
              <v-subheader class="px-0">
                <span style="margin-right: 1em;">
                  {{ $t('archive_mapset') }}
                </span>
                <FileSelect
                  v-model="formData.pathFileNode"
                  :select-file-type="FILE_TYPE.MAPSET"
                  :permissions="['create']"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      color="primary"
                      dark
                      small
                      v-on="on"
                    >
                      {{ $t('select') }}
                    </v-btn>
                  </template>
                </FileSelect>
              </v-subheader>
            </v-input>
            <v-text-field
              v-if="formData.pathFileNode"
              class="flex-nowrap mb-6"
              color="primary"
              readonly
              filled
              single-line
              hide-details="auto"
            >
              <template v-slot:label>
                <template v-for="(breadcrumb, iBreadcrumb) in pathFileNodeBreadcrumbs">
                  <span
                    :key="iBreadcrumb"
                    class="text-no-wrap primary--text"
                    style="font-size: 0.9rem;"
                  >{{ breadcrumb }}</span>
                  <v-icon
                    v-if="iBreadcrumb < pathFileNodeBreadcrumbs.length - 1"
                    :key="`${iBreadcrumb}-divder`"
                    color="primary"
                    small
                  >
                    mdi-chevron-right
                  </v-icon>
                </template>
              </template>
              <template v-slot:append>
                <v-btn
                  color="error"
                  icon small
                  @click="formData.pathFileNode = null"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
            </v-text-field>

            <v-text-field
              v-model="formData.fileName"
              :label="$t('file_name')"
              filled
              :suffix="fileExtensionName"
              :rules="rulesFileName"
            />
          </template>

          <template v-if="editModelFileNode">
            <v-subheader>{{ $t('edit') }}</v-subheader>
            <v-text-field
              class="flex-nowrap mb-6"
              color="primary"
              hide-details="auto"
              readonly
              filled
              single-line
            >
              <template v-slot:label>
                <template v-for="(breadcrumb, iBreadcrumb) in editModelFileNodeBreadcrumbs">
                  <span
                    :key="iBreadcrumb"
                    class="text-no-wrap primary--text"
                    style="font-size: 0.9rem;"
                  >{{ breadcrumb }}</span>
                  <v-icon
                    v-if="iBreadcrumb < editModelFileNodeBreadcrumbs.length - 1"
                    :key="`${iBreadcrumb}-divder`"
                    color="primary"
                    small
                  >
                    mdi-chevron-right
                  </v-icon>
                </template>
              </template>
            </v-text-field>
          </template>
          <v-text-field
            v-model.number="formData.params.nx"
            label="nx"
            type="number"
            step="1"
            :rules="rulesInt"
          />
          <v-text-field
            v-model.number="formData.params.ny"
            label="ny"
            type="number"
            step="1"
            :rules="rulesInt"
          />
          <v-text-field
            v-model.number="formData.params.power"
            label="power"
            type="number"
            :rules="rulesFloat"
          />

          <v-combobox
            v-model="formData.params.OBkey"
            hide-details="auto"
            label="OBkey"
            :items="featureProperties"
            :rules="rulesOBkey"
          />
        </v-card-text>

        <v-card-actions class="pa-3">
          <v-btn
            class="action-btn"
            rounded
            :disabled="isLoading"
            @click="cancel"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            class="action-btn"
            color="secondary"
            rounded
            :disabled="isLoading"
            @click="close"
          >
            {{ $t('close') }}
          </v-btn>

          <v-tooltip
            top
            nudge-top="-15px"
            :disabled="roleEditModelFileNode.update"
          >
            <span>{{ $t('permission_denied') }}</span>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  class="action-btn ml-3"
                  rounded
                  color="primary"
                  :loading="isLoading"
                  :disabled="!roleEditModelFileNode.update"
                  @click="submit"
                >
                  {{ $t('save') }}
                </v-btn>
              </div>
            </template>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import FileSelect from '@/components/FileSelect/FileSelect.vue'

import { SIM_MODEL } from '@/models'
import { roleRoot } from '@/store/modules/files/files'
import {
  FEATURE_MULTI_LAYER,
  FILE_TYPE
} from '@/models/utils'
import {
  validateRequired,
  validateDuplicate
} from '@/assets/js/validate'

import { cloneDeep } from 'lodash'

import { mapState, mapGetters } from 'vuex'

const fileExtensionName = '.json'

const rulesFileName = vm => [
  validateRequired,
  v => validateDuplicate(vm.fileNames)(`${v}${fileExtensionName}`.toLowerCase())
]

const initFormData = (params = null) => ({
  pathFileNode: null,
  fileName: '',
  params: cloneDeep(params) || {
    nx: 21,
    ny: 21,
    power: 2,
    OBkey: ''
  }
})

export default {
  name: 'InterpolatingModelDialog',

  components: {
    FileSelect
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    FILE_TYPE,
    fileExtensionName,
    show: false,
    formData: initFormData(),
    isLoading: false,
    isValid: false,
    rulesPathFileNode: [validateRequired],
    rulesOBkey: [validateRequired],
    rulesInt: [validateRequired],
    rulesFloat: [validateRequired]
  }),

  computed: {
    ...mapState({
      currentLayerNode: state => state.map.currentLayerNode
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute',
      getRoleByResourceId: 'projects/getRoleByResourceId'
    }),
    rulesFileName,

    dialog: {
      get() {
        return this.show
      },
      set(newVal) {
        this.show = newVal
        this.$emit('input', newVal)
      }
    },
    editModelFileNode: {
      get() {
        return this.$store.state.simulation.editModelFileNode
      },
      set(newVal) {
        this.$store.commit('simulation/setState', { editModelFileNode: newVal })
      }
    },

    isEdit() {
      return !!this.editModelFileNode
    },
    project() {
      return this.getProjectByRoute(this.$route)
    },
    isProjectOwner() {
      return this.project?.isOwner
    },
    featureProperties() {
      const firstFeature = this.currentLayerNode?.source?.geoJsonData?.features?.[0]

      if (!firstFeature) {
        return []
      }

      if (FEATURE_MULTI_LAYER.is(firstFeature)) {
        const firstLayerProperties = FEATURE_MULTI_LAYER.getLayerProperties(firstFeature)[0][1]
        return Object.keys(firstLayerProperties)
      }

      return Object.keys(firstFeature.properties)
    },
    fileNames() {
      const mapset = this.formData.pathFileNode

      if (!mapset) return []

      return mapset.children
        .map(file => file.name?.toLowerCase?.())
        .filter(Boolean)
    },
    pathFileNodeBreadcrumbs() {
      if (!this.formData.pathFileNode) {
        return []
      }

      return this.formData.pathFileNode.breadcrumbs
    },
    editModelFileNodeBreadcrumbs() {
      if (!this.editModelFileNode) {
        return []
      }

      return this.editModelFileNode.breadcrumbs
    },
    roleEditModelFileNode() {
      if (!this.isEdit || this.isProjectOwner) {
        return roleRoot
      }

      return this.getRoleByResourceId(this.project, this.editModelFileNode.uuid) || {}
    }
  },

  watch: {
    value: {
      handler(newVal) {
        this.show = newVal

        if (newVal) {
          this.formData = initFormData(this.editModelFileNode?.fileContent?.global)
          this.resetValidation()
        }
      },
      immediate: true
    },
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
      }
    }
  },

  methods: {
    resetValidation() {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    close() {
      this.dialog = false
    },
    cancel() {
      this.close()
      this.editModelFileNode = null
    },
    async submit() {
      await this.$refs.form.validate()

      if (!this.isValid) {
        return
      }

      this.isLoading = true
      const jsonContent = {
        global: this.formData.params
      }

      let newFileNode
      if (!this.isEdit) {
        newFileNode = await this.$store.dispatch('files/createMaplayer', {
          project: this.project,
          parent: this.formData.pathFileNode,
          maplayerName: `${this.formData.fileName}${fileExtensionName}`,
          maplayerType: 'SETTINGS_FILE',
          simulationModelUuid: SIM_MODEL.INTERPOLATING
          // parentMaplayerUuid: this.currentLayerNode.s3Uuid
        })
          .catch(error => {
            this.$store.dispatch('snackbar/showError', { content: error })
            this.isLoading = false

            return Promise.reject(error)
          })

        if (!newFileNode || newFileNode instanceof Error) {
          return
        }
      }
      return this.$store.dispatch('files/updateMaplayerData', {
        project: this.project,
        fileNode: newFileNode || this.editModelFileNode,
        jsonContent
      })
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('success_edited')
          })
          this.close()

          if (!this.isEdit) {
            this.editModelFileNode = newFileNode
          }
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', { content: error })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-btn.v-btn.action-btn {
  padding: 0.286em 1.743em;
  height: unset;
  font-size: 0.875rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;
}
</style>
