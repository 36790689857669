<template>
  <v-row class="tab-item pa-3 pt-5" align="stretch">
    <v-col class="pa-8 py-6">
      <ParameterInput
        :parameter="Head"
      />
    </v-col>
  </v-row>
</template>

<script>
import BaseParametersForm from '../../Base/BaseParametersForm'
import ParameterInput from '@/components/Simulation/ParameterInput'

export default {
  name: 'Head',

  components: {
    ParameterInput
  },
  extends: BaseParametersForm, // 聯繫父層form的相關script

  props: {
    parameters: {
      type: Object,
      required: true
    }
  },

  computed: {
    Head() {
      return this.parameters?.Head || {}
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-item {
  > .col {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
</style>
