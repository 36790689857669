<template>
  <v-edit-dialog>
    <v-progress-linear value="100" :color="editValue" height="12" />

    <template v-slot:input>
      <v-color-picker v-model="editValue" :disabled="disabled||readonly" />
    </template>
  </v-edit-dialog>
</template>

<script>
import hexToRgba from 'hex-to-rgba'

const rgbaToHex = (orig) => {
  var a
  var rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i)
  var alpha = ((rgb && rgb[4]) || '').trim()
  var hex = rgb
    ? (rgb[1] | 1 << 8).toString(16).slice(1) +
    (rgb[2] | 1 << 8).toString(16).slice(1) +
    (rgb[3] | 1 << 8).toString(16).slice(1) : orig

  if (alpha !== '') {
    a = alpha
  } else {
    a = 1
  }
  // multiply before convert to HEX
  a = ((a * 255) | 1 << 8).toString(16).slice(1)
  hex = hex + a

  return `#${hex}`
}

export default {
  name: 'FeatureColorPicker',

  props: {
    value: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    editValue: {
      get() {
        if (this.value.startsWith('#')) {
          return this.value
        }

        return rgbaToHex(this.value)
      },
      set(newVal) {
        this.$emit('input', hexToRgba(newVal))
      }
    }
  }
}
</script>

<style lang="scss">
.v-small-dialog__activator__content {
  width: 100%;
}
</style>
