<template>
  <v-card
    color="grey lighten-3"
    :elevation="0"
    height="100%"
  >
    <v-row
      class="
          draggable
          px-8
          py-3
          bg-ease
          flex-grow-0
        "
      no-gutters
    >
      <v-col class="step-title white--text">
        <!-- {{ title }} -->
      </v-col>
    </v-row>
    <FeatureMultiLayerInfo
      v-if="isMultiLayer"
      :feature="feature"
      v-on="$listeners"
    />
    <FeatureInfoForm
      v-else
      :feature="feature"
      :properties="(feature && feature.properties) || null"
      v-on="$listeners"
    />
  </v-card>
</template>

<script>
import FeatureInfoForm from './FeatureInfoForm.vue'
import FeatureMultiLayerInfo from './FeatureMultiLayerInfo.vue'

import { FEATURE_MULTI_LAYER } from '@/models/utils'

export default {
  name: 'FeatureInfoCard',

  components: {
    FeatureInfoForm,
    FeatureMultiLayerInfo
  },

  props: {
    feature: {
      type: Object,
      default: null
    }
  },

  computed: {
    isMultiLayer() {
      return FEATURE_MULTI_LAYER.is(this.feature)
    }
  }
}
</script>

<style lang="scss" scoped>
.step-title {
  font-size: 1.125rem;
}
</style>
