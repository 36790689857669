var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700","persistent":_vm.isLoading},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",function(){return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v(" Style2D ")])]},{"on":on,"attrs":attrs})]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"color":"grey lighten-3","elevation":0,"height":"100%"}},[_c('v-form',{ref:"form",staticStyle:{"height":"100%"},attrs:{"disabled":_vm.isLoading}},[_c('v-row',{staticClass:"\n          draggable\n          px-8\n          py-3\n          bg-ease\n          flex-grow-0\n        ",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"step-title white--text"},[_vm._v(" "+_vm._s(_vm.$t('page.map.manage_style2d_title',{name:_vm.layerNode.name}))+" ")])],1),_c('v-row',{staticClass:"flex-nowrap",style:({
          height: _vm.roleFileNode.update?'calc(100% - 75px)':'calc(100% - 24px)'
        }),attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"overflow-y":"auto","max-height":"100%","flex-basis":"500px"}},[_c('v-data-table',{staticClass:"property-table pa-3",attrs:{"loading":_vm.isLoading,"headers":_vm.tableHeaders,"items":_vm.properties,"items-per-page":-1,"item-class":function () { return 'property-tr'; },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.value",fn:function(ref){
        var item = ref.item;
return [(item.editable)?_c('FeatureEditer',_vm._b({attrs:{"type":item.dataType,"disabled":_vm.isLoading,"readonly":!_vm.roleFileNode.update,"items":(Array.isArray(item.options) && item.options) || []},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}},'FeatureEditer',item.attrs || {},false)):_c('span',[_vm._v(_vm._s(item.value))])]}}],null,true)})],1)],1),(_vm.roleFileNode.update)?_c('v-card-actions',{staticClass:"v-card__actions pa-3 px-5"},[_c('v-btn',{staticClass:"action-btn",attrs:{"rounded":"","color":"primary","loading":_vm.isLoading},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('set_up'))+" ")]),_c('v-btn',{staticClass:"action-btn ml-5",attrs:{"rounded":"","disabled":_vm.isLoading},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }