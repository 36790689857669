<template>
  <div>
    <FeatureColorPicker
      v-if="type === 'color'"
      v-model="editValue"
      :disabled="disabled"
      :readonly="readonly"
    />
    <v-text-field
      v-if="type === 'number'"
      v-model="editValue"
      type="number"
      :step="step"
      :min="min"
      :max="max"
      :disabled="disabled"
      :readonly="readonly"
    />
    <v-text-field
      v-if="type === 'string'"
      v-model="editValue"
      :disabled="disabled"
      :readonly="readonly"
    />
    <v-select
      v-if="type === 'select'"
      v-model="editValue"
      :disabled="disabled"
      :readonly="readonly"
      :items="items"
    />
  </div>
</template>

<script>
import FeatureColorPicker from './FeatureColorPicker'

export default {
  name: 'FeatureEditer',

  components: {
    FeatureColorPicker
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Number, String, Object, Array],
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'string',
      validator(value) {
        return ['color', 'number', 'string', 'select'].indexOf(value) !== -1
      }
    },
    step: {
      type: Number,
      default: 1
    },
    min: {
      type: Number,
      default: undefined
    },
    max: {
      type: Number,
      default: undefined
    }
  },

  computed: {
    editValue: {
      get() {
        return this.value
      },
      set(newVal, oldVal) {
        if (this.type !== 'number') {
          return this.$emit('input', newVal)
        }

        // number rules

        // NaN value
        const parseVal = parseFloat(newVal)
        if (Number.isNaN(parseVal)) {
          const oriVal = this.value
          this.$emit('input', undefined)
          return this.$nextTick(() => {
            this.$emit('input', parseFloat(oriVal))
          })
        }

        // limit value between min and max
        const min = this.min
        const max = this.max
        if (min != null && parseVal < min) {
          this.$emit('input', undefined)
          return this.$nextTick(() => {
            this.$emit('input', min)
          })
        }
        if (max != null && parseVal > max) {
          this.$emit('input', undefined)
          return this.$nextTick(() => {
            this.$emit('input', max)
          })
        }

        this.$emit('input', parseVal)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
