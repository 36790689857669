import i18n from '@/i18n'

const regexNotaz = /[^a-z]/g
const regexNotazAZ09 = /[^a-zA-Z0-9]/g
const regexNotazAZ09Ul = /[^a-zA-Z0-9_]/g
const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const validateRequired = v => !!v || i18n.t('validate.required')
export const validateEmail = v => {
  return regexEmail.test(v) || i18n.t('validate.email_format_error')
}
export const validateNotEnNum = v => {
  if (!v) return true

  if (v.match(regexNotazAZ09)) {
    return i18n.t('validate.chars_azAZ_09')
  }

  return true
}
export const validateNotEnNumUl = v => {
  if (!v) return true

  if (v.match(regexNotazAZ09Ul)) {
    return i18n.t('validate.chars_azAZ_09_ul')
  }

  return true
}
export const validateDuplicate = (arr, message) => v => {
  if (!Array.isArray(arr) || !v) return true

  return !arr.includes(v.trim()) || message || i18n.t('validate.name_duplicate')
}

export const validateFileExtensionName = allowTypes => fileName => {
  if (!fileName) return true

  const fileNames = fileName.split('.')

  if (fileNames.length < 2) {
    return i18n.t('validate.filename_without_extension')
  }

  const fileType = fileNames.pop()

  if (fileType.match(regexNotaz)) {
    return i18n.t('validate.filename_extension_lowercase')
  }

  const isAllowType = allowTypes.some(allowType =>
    allowType === fileType
  )

  if (!isAllowType) {
    return i18n.t('validate.allow_file_extensions', {
      types: allowTypes.toString()
    })
  }

  return true
}

export const validateFileType = allowTypes => file => {
  if (!file) return true

  if (!file.name) {
    return i18n.t('validate.filename_without_extension')
  }

  return validateFileExtensionName(allowTypes)(file.name)
}
