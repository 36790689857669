<template>
  <v-snackbar
    v-if="drawer && editLayerNode"
    class="drawer-editor-snackbar"
    :timeout="-1"
    :value="snack"
    color="primary"
    top
    left
    outlined
  >
    <v-row no-gutters align="center">
      <v-col>
        {{ editLayerNode.name }}
      </v-col>
      <v-col cols="auto">
        <v-btn
          :disabled="isDrawing"
          icon small
          @click="cancelEdit"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
import { MAP_PAINT_TYPE_DRAW_TYPE } from '@/store/modules/map/map'

import { mapState } from 'vuex'

export default {
  name: 'DrawerEditor',

  data: () => ({
    snack: true
  }),

  computed: {
    ...mapState({
      layerTree: state => state.map.layerTree,
      currentLayerNode: state => state.map.currentLayerNode,
      drawer: state => state.map.drawer.drawer,
      isDrawing: state => state.map.drawer.isDrawing
    }),

    editLayerNode: {
      get() {
        return this.$store.state.map.drawer.editLayerNode
      },
      set(newVal) {
        this.$store.commit('map/drawer/setState', { editLayerNode: newVal })
      }
    },
    currentDrawType: {
      get() {
        return this.$store.state.map.drawer.currentDrawType
      },
      set(newVal) {
        this.$store.commit('map/drawer/setState', { currentDrawType: newVal })
      }
    },
    hasUpdate: {
      get() {
        return this.$store.state.map.drawer.hasUpdate
      },
      set(newVal) {
        this.$store.commit('map/drawer/setState', { hasUpdate: newVal })
      }
    }
  },

  watch: {
    currentLayerNode: {
      handler(newVal, oldVal) {
        if (oldVal) {
          this.cancelEdit()
        }
        this.initEdit()
      }
    }
  },

  mounted() {
    this.initEdit()
  },

  beforeDestroy() {
    if (this.editLayerNode) {
      this.layerTree.setMaplayerVisibility(this.editLayerNode)
      this.editLayerNode = null
    }
  },

  methods: {
    initEdit() {
      const layerNode = this.editLayerNode = this.currentLayerNode

      if (!layerNode || layerNode.is3DFile) {
        this.editLayerNode = null
        return
      }

      this.layerTree.setMaplayerVisibility(layerNode, 'none')

      const featureCollection = layerNode.source.geoJsonData

      this.drawer.add(featureCollection)

      this.currentDrawType = MAP_PAINT_TYPE_DRAW_TYPE[
        layerNode.type
      ]

      this.hasUpdate = false
    },
    cancelEdit() {
      this.layerTree.setMaplayerVisibility(this.editLayerNode)
      this.editLayerNode = null
      this.drawer.deleteAll()
      this.currentDrawType = null
      this.hasUpdate = false
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer-editor-snackbar.v-snack {
  z-index: 100;

  ::v-deep {
    .v-snack__wrapper {
      min-height: unset;

      .v-snack__content {
        padding-top: 6px;
        padding-bottom: 6px;
      }
    }
  }
}
</style>
