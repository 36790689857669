<template>
  <v-form
    ref="form"
    :disabled="isLoading"
    style="height: 100%;"
  >
    <v-row
      class="flex-nowrap"
      no-gutters
      :style="{
        height: roleFileNode.update?'calc(100% - 75px)':'calc(100% - 24px)'
      }"
    >
      <v-col style=" overflow-y: auto; max-height: 100%;flex-basis: 500px;">
        <v-data-table
          class="property-table pa-3"
          :loading="isLoading"
          :headers="tableHeaders"
          :items="showProperties"
          :items-per-page="-1"
          :item-class="() => 'property-tr'"
          hide-default-footer
        >
          <template v-slot:[`item.value`]="{ item }">
            <FeatureCalculatorDialog
              v-if="item.computable"
              :geojson="feature"
              :paint-type="paintType"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  class="action-btn"
                  rounded
                  color="secondary"
                  v-on="on"
                >
                  <v-icon left>
                    mdi-calculator
                  </v-icon>
                  {{ $t('calculate') }}
                </v-btn>
              </template>
            </FeatureCalculatorDialog>
            <FeatureEditer
              v-if="item.editable"
              v-model="item.value"
              :items="Array.isArray(item.options) && item.options"
              :type="item.dataType"
              :disabled="isLoading"
              :readonly="!roleFileNode.update"
              v-bind="item.attrs || {}"
            />
            <span v-else>{{ item.value }}</span>
          </template>
        </v-data-table>
      </v-col>
      <v-col
        class="white ml-3"
        cols="5"
        style=" overflow-y: auto; max-height: 100%;flex-basis: 348px;"
      >
        <v-progress-linear
          v-if="isLoadingDashboards"
          absolute
          color="primary"
          indeterminate
        />
        <IssueCard
          v-else-if="hasNoData"
          :message="$t('page.map.no_dashboard')"
          not-found
          img-width="70%"
          style="margin-top: 60px;"
        />
        <v-hover
          v-for="(dashboard, iDashboard) in dashboards"
          v-else
          v-slot="{ hover }"
          :key="`${mapLayerId}-${iDashboard}`"
        >
          <v-card
            class="dashboard-card mb-7 mx-9"
            :class="{
              'primary--text': !hover,
              primary: hover,
              'mt-7': iDashboard === 0
            }"
            :dark="hover"
            height="100px"
            outlined
            style="cursor: pointer;"
            :disabled="!dashboard.panels.length"
            @click="$emit('click:dashboard', dashboard, properties, layerLabel)"
          >
            <v-card-title>
              <div
                class="text-truncate"
                :title="dashboard.title"
                v-text="dashboard.title"
              />
            </v-card-title>
            <v-card-subtitle>
              <v-row
                v-if="dashboard.panels.length"
                class="dashboard-description"
                :title="dashboard.description"
                no-gutters
                v-text="dashboard.description"
              />
              <!-- tip no panels -->
              <v-row
                v-else
                lass="dashboard-description"
                no-gutters
              >
                {{ $t('page.map.no_panel') }}
              </v-row>
            </v-card-subtitle>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>

    <v-card-actions
      v-if="roleFileNode.update"
      class="pa-3 px-5"
    >
      <v-btn
        class="action-btn"
        rounded
        color="primary"
        :loading="isLoading"
        @click="submit"
      >
        {{ $t('update') }}
      </v-btn>
      <v-btn
        class="action-btn ml-5"
        rounded
        :disabled="isLoading"
        @click="$emit('cancel')"
      >
        {{ $t('cancel') }}
      </v-btn>

      <v-spacer />

      <ModflowParametersDialog
        v-if="
          [
            SIM_MODEL.MODFLOW,
            SIM_MODEL.FEMWATER_MESH
          ].includes(model)
        "
        :layer-node="layerNode"
        :feature-index="featureIndex"
        :layer-index="layerIndex"
      >
        <template v-slot:activator="{ on, attrs}">
          <v-tooltip :disabled="!!editSimParamFileNode" top>
            <template v-slot:activator="{ on:onTooltip, attrs:attrsTooltip }">
              <div v-bind="attrsTooltip" v-on="onTooltip">
                <v-btn
                  v-bind="attrs"
                  class="action-btn"
                  color="secondary"
                  rounded
                  :disabled="!editSimParamFileNode"
                  v-on="on"
                >
                  {{ $t('simulation_parameter_abbreviation') }}
                </v-btn>
              </div>
            </template>
            <span>{{ $t('page.modflow.tip_select_param_file_first') }}</span>
          </v-tooltip>
        </template>
      </ModflowParametersDialog>
    </v-card-actions>
  </v-form>
</template>

<script>
import FeatureEditer from '@/components/Map/FeatureViewer/FeatureEditer'
import IssueCard from '@/components/IssueCard'
import ModflowParametersDialog from '@/components/Simulation/Modflow/Parameters/ModflowParametersDialog.vue'
import FeatureCalculatorDialog from '@/components/Map/FeatureCalculator/FeatureCalculator.vue'

import { SIM_MODEL } from '@/models'
import {
  SYSTEM_PREFIX_PROPS,
  STYLE2D_KEYS,
  FEATURE_MULTI_LAYER
} from '@/models/utils'

import { uniqBy } from 'lodash'

import { roleRoot } from '@/store/modules/files/files'

import { mapState, mapGetters } from 'vuex'

const tableHeaders = vm => [
  {
    text: vm.$t('property'),
    value: 'propertyKey'
  },
  {
    text: vm.$t('value'),
    value: 'value'
  }
]

export default {
  name: 'FeatureInfoForm',

  components: {
    FeatureEditer,
    IssueCard,
    ModflowParametersDialog,
    FeatureCalculatorDialog
  },

  props: {
    feature: {
      type: Object,
      default: null
    },
    properties: {
      type: Object,
      default: null
    },
    layerLabel: {
      type: [String, Number],
      default: null
    }
  },

  data: vm => ({
    SIM_MODEL,
    isLoading: false
  }),

  computed: {
    ...mapState({
      model: state => state.simulation.model,
      fileTree: state => state.files.fileTree,
      layerTree: state => state.map.layerTree,
      isLoadingDashboards: state => state.dashboards.isLoadingDashboards
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute',
      getRoleByResourceId: 'projects/getRoleByResourceId',
      map: 'map/map',
      getDashboardsByMaplayerId: 'dashboards/getDashboardsByMaplayerId'
    }),
    tableHeaders,

    project() {
      return this.getProjectByRoute(this.$route)
    },
    mapLayerId() {
      return this.feature?.layer?.id
    },
    fileNode() {
      return this.fileTree.findNodeBF(this.fileTree.root, this.mapLayerId)
    },
    layerNode() {
      if (!this.layerTree) {
        return
      }
      return this.layerTree.findNodeBF(this.layerTree.root, this.mapLayerId)
    },
    editSimParamFileNode() {
      return this.layerNode?.editSimParamFileNode
    },
    featureIndex() {
      return this.feature?.id
    },
    layerIndex() {
      return FEATURE_MULTI_LAYER.getLayerIndexByLayerLabel(this.feature, this.layerLabel)
    },
    roleFileNode() {
      if (!this.project) {
        return {}
      }

      if (this.project.isOwner) {
        return roleRoot
      }

      return this.getRoleByResourceId(this.project, this.fileNode?.uuid) || {}
    },
    hasNoData() {
      return !Array.isArray(this.dashboards) || !this.dashboards.length
    },
    dashboards() {
      return this.getDashboardsByMaplayerId(this.mapLayerId)
    },
    paintType() {
      return this?.feature?.layer?.type
    },
    canCalculate() {
      const allowPaintTypes = ['line', 'fill']

      return allowPaintTypes.includes(this.paintType)
    },
    calcProperties() {
      if (!this.canCalculate) {
        return []
      }

      const propertyKey = `#${
        this.paintType === 'fill'
        ? this.$t('polygon_area')
        : this.$t('line_string_distance')
      }`

      return [
        {
          computable: true,
          propertyKey
        }
      ]
    },
    style2dProperties() {
      const targetLayerType = this.paintType
      const properties = this.feature.properties // style2D properties

      return Object.values(STYLE2D_KEYS)
        .filter(({ layerType }) => layerType.includes(targetLayerType))
        .map(({ key, defaultValue, dataType, attrs, options = [] }) => ({
          editable: true,
          style2dKey: key,
          propertyKey: SYSTEM_PREFIX_PROPS && key.replace(SYSTEM_PREFIX_PROPS, '#'),
          value: properties[key] || defaultValue,
          options: options.map(o => ({
            ...o,
            text: this.$t(o.text)
          })),
          dataType,
          attrs
        }))
    },
    showProperties() {
      // feature properties data structure
      if (!this.feature) return []

      const convertPropVal = (val) => {
        if (val === 'null' || val == null) {
          return
        }
        let result
        switch (typeof val) {
          case 'object':
            result = JSON.stringify(val)
            break
          case 'number':
            result = val
            break

          default:
            result = String(val)
            break
        }
        return result
      }

      return [
        ...this.calcProperties,
        ...this.style2dProperties,
        // general properties
        ...Object.entries(this.properties)
          .filter(([propertyKey]) => !SYSTEM_PREFIX_PROPS || !propertyKey.startsWith(SYSTEM_PREFIX_PROPS))
          .map(([propertyKey, val]) => ({
            propertyKey,
            value: convertPropVal(val)
          }))
          .sort((a, b) => a.propertyKey.localeCompare(b.propertyKey))
      ]
    }
  },

  watch: {
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
      }
    }
  },

  methods: {
    submit() {
      this.isLoading = true
      // try catch 是為了讓exception可以結束loading
      try {
        const sourceId = this.feature.source

        // 取得feature id的方法, 用id找到要更新的feature
        // querySourceFeatures會把feature根據gemoetry切成片段(multi系列)
        // 用id取uniq, 再用原始資料的geometry做updateData
        // feature id是feature的陣列index
        const features = uniqBy(
          this.map.querySourceFeatures(sourceId, {
            sourceLayer: this.mapLayerId
          }),
          'id'
        ).sort((a, b) => a.id > b.id)
        const feature = features.find(f => f.id === this.feature.id)
        const oldData = this.fileNode.fileContent
        const newStyle2dProperties = Object.fromEntries(
          this.style2dProperties.map(({ style2dKey, propertyKey, value }) => [
            style2dKey || propertyKey,
            value
          ])
        )
        const newData = {
          ...oldData,
          features: features.map(({ id, properties }) => {
          // 要有id的features, 判斷要更新的feature
          // multi系列的geometry在features裡面只是片段
          // 要用切片之前的geometry
            const oldFeature = oldData.features[id] // ease feature
            const multiLayerProperties = FEATURE_MULTI_LAYER.getLayerPropertiesObject(oldFeature)
            return {
              ...oldFeature,
              properties: id === feature.id
                ? {
                  ...properties,
                  ...newStyle2dProperties,
                  ...multiLayerProperties
                }
                : properties
            }
          })
        }

        this.map.getSource(sourceId).setData(newData)
        this.fileNode.setFileContent(newData)
        return this.$store.dispatch('files/updateMaplayerData', {
          project: this.project,
          fileNode: this.fileNode,
          jsonContent: newData
        })
          .then(() => {
            this.$emit('cancel')
          })
          .catch(error => {
            this.$store.dispatch('snackbar/showError', {
              content: error
            })
          })
          .finally(() => {
            this.isLoading = false
          })
      } catch (error) {
        this.isLoading = false
        this.$store.dispatch('snackbar/showError', {
          content: error
        })
        throw error
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.property-table {
  min-height: 100%;

  ::v-deep .v-data-table-header {
    tr th {
      font-size: 1rem;
      font-weight: bold;
      color: #343843;
    }
  }

  ::v-deep .property-tr {
    &:nth-of-type(odd) {
      background-color: #f0f3f6;
    }

    td {
      max-width: 120px;
      font-size: 1rem;
      overflow-wrap: break-word;

      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
  }
}

.dashboard-card {
  border: 1px solid $color-primary;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);

  .dashboard-description {
    @include multi-line-trancate(2);
  }
}

.v-btn.v-btn.action-btn {
  padding: 0.286em 1.743em;
  height: unset;
  font-size: 0.875rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;
}
</style>
