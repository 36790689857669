<template>
  <v-card :width="280" flat>
    <v-card-title class="pa-0 py-2 text-subtitle-2">
      <v-row no-gutters>
        <v-col cols="auto" class="text-overline">
          {{ title }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6">
          <v-text-field
            v-model="date"
            filled
            hide-details
            @focus="focusing = 'date'"
            @blur="onChange"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="time"
            filled
            hide-details
            @focus="focusing = 'time'"
            @blur="onChange"
          />
        </v-col>
      </v-row>
    </v-card-title>

    <!-- Use v-if to reset time-picker when click:minute -->
    <!-- Don't use v-show to control visibility unless you can reset time-picker -->
    <!-- Listen xx--date-format to avoid user input Invalid Date -->
    <v-date-picker
      v-if="focusing === 'date'"
      v-model="date"
      no-title
      scrollable
      :header-date-format="formatDateHeader"
      :max="limitDate('max')"
      :min="limitDate('min')"
      @change="onChange"
    />
    <!-- Listen onInput to avoid user input Invalid Date -->
    <v-time-picker
      v-else
      v-model="time"
      format="24hr"
      scrollable
      no-title
      :max="limitTime('max')"
      :min="limitTime('min')"
      @click:minute="onClickMinute"
      @input="onInputTime"
    />
  </v-card>
</template>

<script>
import moment from 'moment'

moment.suppressDeprecationWarnings = true

const dateFormat = 'YYYY-MM-DD'
const timeFormat = 'HH:mm'
const dateHeaderFormat = 'MMMM YYYY'

export default {
  name: 'DateTimePicker',

  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    max: {
      type: String,
      default: ''
    },
    min: {
      type: String,
      default: ''
    }
  },

  data: vm => ({
    focusing: 'date',
    date: moment(vm.value).format(dateFormat),
    time: moment(vm.value).format(timeFormat)
  }),

  computed: {
    dateTimeFormat() {
      return `${this.date} ${this.time}`
    },
    limitMoment() {
      return key => {
        if (!this[key]) return ''

        const m = moment(this[key])

        if (!m.isValid()) return ''

        return m
      }
    },
    limitDate() {
      return key => {
        if (!this.limitMoment(key)) return ''

        return this.limitMoment(key).format(dateFormat)
      }
    },
    limitTime() {
      return key => {
        if (!this.limitMoment(key)) return ''

        const isUnLimit =
          key === 'min'
            ? this.limitDate(key) < this.date
            : this.limitDate(key) > this.date

        if (isUnLimit) return ''

        const isDisabled =
          key === 'min'
            ? this.limitDate(key) > this.date
            : this.limitDate(key) < this.date

        if (isDisabled) return '24:00'

        return this.limitMoment(key).format(timeFormat)
      }
    }
  },

  watch: {
    value(newVal) {
      const m = moment(newVal)

      if (!m.isValid()) return

      this.date = m.format(dateFormat)
      this.time = m.format(timeFormat)
    }
  },

  mounted() {
    this.onChange()
  },

  methods: {
    onChange() {
      this.$emit('input', this.dateTimeFormat)
    },

    onClickMinute() {
      this.focusing = 'date'

      document.activeElement.blur()
    },

    formatDateHeader(date) {
      // To avoid exception message when user input invalid date
      const mDate = moment(date)

      const isValidDate = mDate.isValid()

      if (!isValidDate) {
        this.date = moment().format(dateFormat)

        return moment().format(dateHeaderFormat)
      }

      return mDate.format(dateHeaderFormat)
    },

    onInputTime(timeString) {
      // Avoid user input Invalid Date

      if (typeof timeString !== 'string') return

      this.time = timeString
        .split(':')
        .map(Number)
        .map(num => (isNaN(num) ? '00' : num < 10 ? `0${num}` : `${num}`))
        .join(':')

      this.onChange()
    }
  }
}
</script>

<style lang="scss" scoped></style>
