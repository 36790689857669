<template>
  <v-dialog
    v-model="dialog"
    max-width="700"
    :retain-focus="false"
    persistent
    no-click-animation
    hide-overlay
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs" :disabled="!hasLegends" />
    </template>

    <v-card
      min-height="200"
    >
      <v-card-title class="draggable bg-ease white--text">
        {{ $t('page.map.manage_legend_title') }}

        <v-spacer />

        <v-btn
          dark
          icon
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-tabs
        v-model="tab"
        grow
        background-color="grey lighten-3"
        center-active
      >
        <v-tab
          v-for="(legend,iLegend) in legends"
          :key="iLegend"
          class=" text-none"
        >
          {{ legend.scalarsName }}
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tab"
      >
        <v-tab-item
          v-for="(legend,iLegend) in legends"
          :key="iLegend"
          :transition="false"
          :reverse-transition="false"
        >
          <LegendCard :layer-node="layerNode" :legend="legend" />
        </v-tab-item>
      </v-tabs-items>

      <v-card-actions class="pa-5">
        <v-btn
          class="action-btn"
          rounded
          @click="cancel"
        >
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LegendCard from './LegendCard'

import { LayerNode } from '@/models'

import { mapGetters } from 'vuex'

export default {
  name: 'LegendsDialog',

  components: {
    LegendCard
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    layerNode: {
      type: LayerNode,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    dialog: false,
    isValid: true,
    tab: 0
  }),

  computed: {
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute'
    }),

    // dialog: {
    //   get() {
    //     return this.value
    //   },
    //   set(newVal) {
    //     this.$emit('input', newVal)
    //   }
    // },

    settings() {
      if (this.layerNode.stagingFile) {
        return this.layerNode.stagingFile.settings
      }

      return this.layerNode.settings
    },
    legends() {
      return this.settings?.plot?.legends
    },
    hasLegends() {
      return Array.isArray(this.legends) && this.legends.length
    },

    project() {
      return this.getProjectByRoute(this.$route)
    }
  },

  watch: {
    layerNode(newVal) {
      if (newVal) {
        this.init()
      }
    }
  },

  methods: {
    init() {
      this.tab = 0
    },
    close() {
      this.dialog = false
    },
    cancel() {
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.v-btn.v-btn.action-btn {
  padding: 0.286em 1.743em;
  height: unset;
  font-size: 0.875rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;
}
</style>
