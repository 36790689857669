<template>
  <v-list v-if="showFolderNodes.length" class="folder-list" dense>
    <v-list-item-group
      v-model="selected"
      color="primary"
    >
      <v-list-item
        v-for="(folderNode, iFolderNode) in showFolderNodes"
        :key="folderNode.uuid||iFolderNode"
        :value="folderNode"
        :ripple="false"
      >
        <v-list-item-content>
          <v-list-item-title>
            <v-row
              no-gutters
              align="center"
            >
              <v-col cols="auto">
                <v-btn
                  :ripple="false"
                  :disabled="!iFolderNode"
                  small icon
                  @click.stop="moveToFront(folderNode)"
                >
                  <v-icon small>
                    mdi-chevron-up
                  </v-icon>
                </v-btn>
                <v-btn
                  :ripple="false"
                  :disabled="iFolderNode===showFolderNodes.length-1"
                  small icon
                  @click.stop="moveToBack(folderNode)"
                >
                  <v-icon small>
                    mdi-chevron-down
                  </v-icon>
                </v-btn>
              </v-col>
              <!-- <v-col cols="auto">
                {{ `${iFolderNode + 1}`.padStart(2,'0') }}
              </v-col> -->
              <v-col cols="auto">
                <v-btn
                  icon small
                  @click.stop="toggleVisible(folderNode)"
                >
                  <v-icon small>
                    {{ folderNode.visible?'mdi-eye':'mdi-eye-off' }}
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col v-if="folderNode.animatable" cols="auto">
                <v-btn
                  :title="$t('play_animation')"
                  icon small
                  @click.stop="animationFolder = folderNode"
                >
                  <v-icon small>
                    mdi-motion-play-outline
                  </v-icon>
                </v-btn>
              </v-col>
              <!-- NOTE: 不設定寬度自動 truncate的話不能設cols="auto"
                   flex-basis不能設為auto-->
              <v-col class="text-truncate" :title="folderNode.name">
                {{ folderNode.name }}
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-if="!isReadonly">
          <v-btn
            icon small
            @click.stop="removeNode(folderNode)"
          >
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'FolderList',

  computed: {
    ...mapState({
      currentFolderNode: state => state.map.currentFolderNode
    }),
    ...mapGetters({
      folderNodes: 'map/folderNodes',
      isMapModelByRoute: 'map/isMapModelByRoute'
    }),

    selected: {
      get() {
        return this.currentFolderNode
      },
      set(newVal) {
        this.$store.commit('map/setState', {
          currentFolderNode: newVal
        })
      }
    },

    animationFolder: {
      get() {
        return this.$store.state.map.animationFolder
      },
      set(newVal) {
        this.$store.commit('map/setState', {
          animationFolder: newVal
        })
      }
    },

    isMapModel() {
      return this.isMapModelByRoute(this.$route)
    },
    isReadonly() {
      return !this.isMapModel
    },
    showFolderNodes() {
      return [...this.folderNodes].reverse()
    }
  },

  methods: {
    toggleVisible(node) {
      return this.$store.dispatch('map/toggleVisible', { node })
    },
    removeNode(node) {
      return this.$store.dispatch('map/removeNode', { node })
    },
    moveToFront(node) {
      const beforeNode = this.folderNodes[node.index + 2]

      this.$store.dispatch('map/moveNode', {
        node,
        beforeNode
      })
    },
    moveToBack(node) {
      const beforeNode = this.folderNodes[node.index - 1]

      if (!beforeNode) {
        return
      }

      this.$store.dispatch('map/moveNode', {
        node,
        beforeNode
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.folder-list {
  .v-list-item--dense .v-list-item__title,
  .v-list-item--dense .v-list-item__subtitle,
  &.v-list--dense .v-list-item .v-list-item__title,
  &.v-list--dense .v-list-item .v-list-item__subtitle {
    font-size: 1rem;
    font-weight: normal;
  }

  .v-list-item {
    padding: 0 0.625rem;
    min-height: 30px;

    .v-list-item__content {
      padding: 0;
    }

    .v-list-item__action {
      margin: 0;
    }
  }
}
</style>
