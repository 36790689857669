<template>
  <div class="d-flex flex-column" style="height: 100%;">
    <v-tabs
      v-model="tab"
      class="flex-grow-0"
      background-color="transparent"
    >
      <v-tab
        v-for="(layer, iLayer) in layers"
        :key="iLayer"
      >
        {{ layer[2] }}
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="tab"
      class="flex-grow-1"
    >
      <v-tab-item
        v-for="(layer, iLayer) in layers"
        :key="iLayer"
        :reverse-transition="false"
        :transition="false"
      >
        <v-card
          color="grey lighten-3"
          :elevation="0"
          height="100%"
        >
          <FeatureInfoForm
            :feature="feature"
            :properties="layer[1]"
            :layer-label="layer[2]"
            v-on="$listeners"
          />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import FeatureInfoForm from './FeatureInfoForm.vue'

import { FEATURE_MULTI_LAYER } from '@/models/utils'

export default {
  name: 'FeatureMultiLayerInfo',

  components: {
    FeatureInfoForm
  },

  props: {
    feature: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    tab: null
  }),

  computed: {
    layers() {
      if (!this.feature) {
        return []
      }

      return FEATURE_MULTI_LAYER.getLayerProperties(this.feature)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-window__container {
    height: 100%;

    .v-window-item {
      height: 100%;
    }
  }
}
</style>
