<template>
  <v-dialog
    v-model="dialog"
    content-class="feature-dialog"
    width="920"
    eager
    :persistent="$refs.infoCard && $refs.infoCard.isLoading"
    @click:outside="init"
  >
    <v-stepper v-model="step" class="feature-stepper">
      <v-stepper-items>
        <v-stepper-content step="1">
          <FeatureInfoCard
            ref="infoCard"
            :feature="feature"
            :title="title"
            @click:dashboard="showPanels"
            @cancel="cancel"
          />
        </v-stepper-content>
        <v-stepper-content step="2">
          <FeaturePanels
            v-if="currentDashborad"
            :properties="currentProperties"
            :dashboard="currentDashborad"
            :layer-label="currentLayerLabel"
            @click:back="step = 1"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import FeatureInfoCard from './FeatureInfo/FeatureInfoCard'
import FeaturePanels from './FeaturePanels'

export default {
  name: 'FeatureViewer',

  components: {
    FeatureInfoCard,
    FeaturePanels
  },

  props: {
    value: {
      // 為feature object或false
      type: [Boolean, Object],
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    step: 1,
    currentDashborad: null,
    currentProperties: null,
    currentLayerLabel: null
  }),

  computed: {
    dialog: {
      get() {
        return !!this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    },
    feature() {
      return this.value || null
    }
  },

  methods: {
    init() {
      this.step = 1
      this.currentDashborad = null
    },
    showPanels(dashboard, properties, layerLabel = null) {
      this.step = 2
      this.currentDashborad = dashboard
      this.currentProperties = properties
      this.currentLayerLabel = layerLabel
    },
    cancel() {
      this.init()
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss">
.feature-dialog {
  height: 70vh;
  min-height: 600px;
}
</style>

<style lang="scss" scoped>
.feature-stepper {
  height: 100%;

  .v-stepper__items {
    height: 100%;

    .v-stepper__content {
      padding: 0;
      height: 100%;

      ::v-deep .v-stepper__wrapper {
        height: 100%;
      }
    }
  }
}
</style>
