<template>
  <v-dialog
    ref="legendDialog"
    v-model="dialog"
    :transition="false"
    :retain-focus="false"
    content-class="legend-dialog elevation-0"
    max-width="100%"
    width="50%"
    hide-overlay
    persistent
    no-click-animation
    eager
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs" />
    </template>

    <v-card
      ref="card"
      class="legend-card"
      height="100%"
      min-height="96px"
      :loading="legend.isPloting"
    >
      <v-card-title class="pa-2 align-center">
        <v-row
          class="flex-nowrap"
          no-gutters
        >
          <v-col
            class="ml-5 mr-8"
            cols="auto"
            no-gutters
            align="center"
          >
            {{ scalarsName }}
          </v-col>
          <v-col
            v-if="legend.isPloting"
            class="grey--text text-body-1 d-flex align-center"
            style="white-space: nowrap;"
          >
            {{ $t('plotting_with_dot') }}
          </v-col>
          <v-spacer />
          <v-col
            v-if="breadcrumbs"
            class="
              mt-2
              mr-4
              d-flex
              align-stretch
              text-subtitle-2
            "
            cols="auto"
          >
            <v-row class="flex-nowrap" no-gutters align="center">
              <template v-for="(breadcrumb, iBreadcrumb) in breadcrumbs">
                <span
                  :key="iBreadcrumb"
                  class="text-no-wrap grey--text"
                  :class="{
                    'primary--text': iBreadcrumb===breadcrumbs.length-1,
                  }"
                >{{ breadcrumb }}</span>
                <v-icon
                  v-if="iBreadcrumb < breadcrumbs.length - 1"
                  :key="`${iBreadcrumb}-divder`"
                  color="grey"
                  small
                >
                  mdi-chevron-right
                </v-icon>
              </template>
            </v-row>
          </v-col>
          <!-- <v-spacer /> -->
          <v-col cols="auto">
            <v-btn
              class="align-self-start"
              x-small
              icon
              @click="close()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <ColorBar
        :colors="legends"
        :max-labels="maxLabels"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { LayerNode } from '@/models'

import { resizable, draggable } from '@/plugins/interactjs'
import ColorBar from '@/components/ColorBar'

export default {
  name: 'LegendBarDialog',

  components: {
    ColorBar
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    layerNode: {
      type: LayerNode,
      required: true
    },
    legend: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    minWidth: 240,
    widthColorBar: 0
  }),

  computed: {
    dialog: {
      get() {
        // return this.legend.visible
        return this.value
      },
      set(newVal) {
        // this.legend.visible = newVal
        this.$emit('input', newVal)
      }
    },

    maxLabels() {
      if (!this.widthColorBar) {
        return null
      }

      let maxLabels = Math.round(3 * (this.widthColorBar / this.minWidth))
      if (maxLabels % 2 === 0) {
        maxLabels -= 1
      }

      return maxLabels
    },
    scalarsName() {
      return this.legend.scalarsName
    },
    legends() {
      const legends = this.legend.legends

      if (!Array.isArray(legends)) {
        return []
      }

      return legends.map(legend => ({
        ...legend,
        label: legend.level
      }))
    },
    breadcrumbs() {
      if (!this.layerNode) {
        return []
      }

      return this.layerNode.breadcrumbs
    }
  },

  created() {
    this.widthColorBar = window.innerWidth / 2
  },

  mounted() {
    const dialogContent = this.$refs?.legendDialog?.$children?.[0]?.$el?.children?.[0]

    if (dialogContent) {
      draggable(dialogContent)
      resizable(dialogContent, {
        restrictSize: {
          min: {
            width: this.minWidth,
            height: 96
          }
        },
        onmove: event => {
          this.widthColorBar = event.rect.width
        }
      })
    }
  },

  methods: {
    close() {
      this.dialog = false
    }
  }
}
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
.legend-card {
  user-select: none;
}
</style>
