<template>
  <v-snackbar
    class="animation-player-snackbar"
    :value="snack"
    app
    bottom
    left
    light
    timeout="-1"
  >
    <div v-if="folder">
      <v-row
        class=" mb-6"
        no-gutters
        align="center"
      >
        <v-col
          class="snack-draggable text-truncate"
          :title="folder.name"
        >
          {{ folder.name }}
        </v-col>
        <v-col
          class="ml-2 mr-n3 mt-n3"
          cols="auto"
        >
          <v-btn
            icon
            small
            @click="close"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        class="mb-3"
        no-gutters
        justify="space-around"
      >
        <v-col cols="auto">
          <v-btn
            v-if="!isPlaying"
            color="primary"
            fab depressed x-small
            @click="play"
          >
            <v-icon>mdi-play</v-icon>
          </v-btn>
          <v-btn
            v-else
            color="primary"
            icon fab depressed x-small
            @click="pause"
          >
            <v-icon>mdi-pause</v-icon>
          </v-btn>
        </v-col>

        <v-col cols="auto">
          <v-row
            no-gutters
            justify="center"
            align="center"
            style="height: 100%;"
          >
            {{ folder.animation.index }}/{{ folder.frameCount }}
          </v-row>
        </v-col>

        <v-col cols="auto">
          <v-btn
            icon fab depressed x-small
            @click="stop"
          >
            <v-icon>
              mdi-stop
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row
        no-gutters
      >
        <v-col>
          <v-slider
            :value="folder.animation.index"
            hide-details="auto"
            :step="1"
            :max="folder.frameCount"
            :min="1"
            thumb-label
            @change="onSliderChange"
          />
        </v-col>
      </v-row>
    </div>
  </v-snackbar>
</template>

<script>
import { Node } from '@/models'
import { mapState } from 'vuex'

export default {
  name: 'AnimationPlayer',

  props: {
    folder: {
      type: Node,
      default: null
    }
  },

  computed: {
    ...mapState({
      layerTree: state => state.map.layerTree
    }),

    snack() {
      return !!this.folder
    },
    isPlaying() {
      return this.folder?.isAnimationPlaying
    },
    tickLabels() {
      if (!this.folder) {
        return []
      }

      return [...Array(this.folder.frameCount + 1).keys()].slice(1)
    }
  },

  watch: {
    snack: {
      handler(newVal) {
        if (newVal && this.folder) {
          this.play()
        }
      }
    },
    $route(newRoute, oldRoute) {
      if (
        this.folder &&
        oldRoute.name === 'MapViewer'
      ) {
        this.close()
      }
    }
  },

  methods: {
    close() {
      this.stop()

      this.$emit('close')

      if (this.$route.name !== 'Map') {
        this.$router.push({
          name: 'Map',
          params: this.$route.params,
          query: this.$route.query
        })
      }
    },
    play() {
      this.layerTree.playAnimation(this.folder)

      if (this.$route.name !== 'MapViewer') {
        this.$router.push({
          name: 'MapViewer',
          params: this.$route.params,
          query: this.$route.query
        })
      }
    },
    stop() {
      this.layerTree.stopAnimation(this.folder)
    },
    pause() {
      this.layerTree.pauseAnimation(this.folder)
    },
    onSliderChange(newVal) {
      this.pause()
      const frameIndex = newVal - 1
      this.layerTree.setAnimationFrame(this.folder, frameIndex)
    }
  }
}
</script>

<style lang="scss" scoped>
.animation-player-snackbar.v-snack {
  ::v-deep .v-snack__wrapper {
    max-width: 360px;

    .v-snack__content {
      width: 100%;
    }
  }
}
</style>
