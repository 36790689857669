<template>
  <v-snackbar
    class="simulation-model-snackbar transparent"
    :timeout="-1"
    :value="snack"
    :elevation="0"
    min-width="unset"
    color="transparent"
    bottom
    light
  >
    <v-select
      v-model="model"
      class="align-center"
      :loading="isLoadingModels"
      :items="models"
      solo
      dense
      rounded
      hide-details="auto"
    >
      <template v-slot:item="{ item }">
        {{ $t(item.text) }}
      </template>
      <template v-slot:selection="{ item }">
        {{ $t(item.text) }}
      </template>
      <template v-slot:append-outer>
        <SimulationModeMenu v-if="!noMenuSims.includes(model)" />
      </template>
    </v-select>
  </v-snackbar>
</template>

<script>
import SimulationModeMenu from './SimulationModelMenu.vue'
import { SIM_MODEL } from '@/models'

import { mapState } from 'vuex'

const { FEMWATER, MASSBALANCE } = SIM_MODEL
const noMenuSims = [FEMWATER, MASSBALANCE]

export default {
  name: 'SimulationModeSelect',

  components: {
    SimulationModeMenu
  },

  data: vm => ({
    noMenuSims,
    snack: true
  }),

  computed: {
    ...mapState({
      models: state => state.simulation.models,
      isLoadingModels: state => state.simulation.isLoadingModels
    }),

    model: {
      get() {
        return this.$store.state.simulation.model
      },
      set(newVal) {
        this.$store.commit('simulation/setState', {
          model: newVal,
          editModelFileNode: null
        })
      }
    }
  },

  watch: {
    model: {
      handler() {
        this.resetEditSimParamFileNode()
      }
    }
  },

  methods: {
    resetEditSimParamFileNode() {
      this.$store.dispatch('map/resetEditSimParamFileNode')
    }
  }
}
</script>

<style lang="scss" scoped>
.simulation-model-snackbar.v-snack {
  z-index: 100;

  ::v-deep {
    .v-snack__wrapper {
      min-width: unset;
      min-height: unset;

      .v-snack__content {
        padding-top: 6px;
        padding-bottom: 6px;
      }
    }
  }
}
</style>
