<template>
  <v-dialog
    v-model="dialog"
    max-width="700"
    :persistent="isLoading"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs">
        <v-btn v-bind="attrs" v-on="on">
          Style2D
        </v-btn>
      </slot>
    </template>
    <v-card
      color="grey lighten-3"
      :elevation="0"
      height="100%"
    >
      <v-form
        ref="form"
        :disabled="isLoading"
        style="height: 100%;"
      >
        <v-row
          class="
            draggable
            px-8
            py-3
            bg-ease
            flex-grow-0
          "
          no-gutters
          align="center"
        >
          <v-col class="step-title white--text">
            {{ $t('page.map.manage_style2d_title',{name:layerNode.name}) }}
          </v-col>
        </v-row>
        <v-row
          class="flex-nowrap"
          no-gutters
          :style="{
            height: roleFileNode.update?'calc(100% - 75px)':'calc(100% - 24px)'
          }"
        >
          <v-col style=" overflow-y: auto; max-height: 100%;flex-basis: 500px;">
            <v-data-table
              class="property-table pa-3"
              :loading="isLoading"
              :headers="tableHeaders"
              :items="properties"
              :items-per-page="-1"
              :item-class="() => 'property-tr'"
              hide-default-footer
            >
              <template v-slot:[`item.value`]="{ item }">
                <FeatureEditer
                  v-if="item.editable"
                  v-model="item.value"
                  :type="item.dataType"
                  :disabled="isLoading"
                  :readonly="!roleFileNode.update"
                  :items="(Array.isArray(item.options) && item.options) || []"
                  v-bind="item.attrs || {}"
                />
                <span v-else>{{ item.value }}</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-card-actions
          v-if="roleFileNode.update"
          class="v-card__actions pa-3 px-5"
        >
          <v-btn
            class="action-btn"
            rounded
            color="primary"
            :loading="isLoading"
            @click="submit"
          >
            {{ $t('set_up') }}
          </v-btn>
          <v-btn
            class="action-btn ml-5"
            rounded
            :disabled="isLoading"
            @click="close"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import FeatureEditer from '@/components/Map/FeatureViewer/FeatureEditer'

import { mapState, mapGetters } from 'vuex'
import { LayerNode } from '@/models'
import { SYSTEM_PREFIX_PROPS, STYLE2D_KEYS } from '@/models/utils'

import { roleRoot } from '@/store/modules/files/files'

const tableHeaders = vm => [
  {
    text: vm.$t('property'),
    value: 'propertyKey'
  },
  {
    text: vm.$t('value'),
    value: 'value'
  }
]

export default {
  name: 'Style2DEditor',

  components: {
    FeatureEditer
  },

  props: {
    layerNode: {
      type: LayerNode,
      required: true
    }
  },

  data: vm => ({
    properties: [],
    dialog: false,
    isLoading: false
  }),

  computed: {
    ...mapState({
      fileTree: state => state.files.fileTree,
      layerTree: state => state.map.layerTree
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute',
      getRoleByResourceId: 'projects/getRoleByResourceId',
      map: 'map/map'
    }),
    tableHeaders,

    project() {
      return this.getProjectByRoute(this.$route)
    },
    fileNode() {
      return this.fileTree.findNodeBF(this.fileTree.root, this.layerNode.uuid)
    },
    roleFileNode() {
      if (!this.project) {
        return {}
      }

      if (this.project.isOwner) {
        return roleRoot
      }

      return this.getRoleByResourceId(this.project, this.fileNode?.uuid) || {}
    }
  },

  watch: {
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
        this.formatProperties()
      }
    },
    dialog: {
      handler(newVal) {
        if (newVal) {
          this.formatProperties()
        }
      },
      immediate: true
    }
  },

  methods: {
    formatProperties() {
      if (!this.layerNode) {
        return
      }

      const targetLayerType = this.layerNode.type

      this.properties = Object.values(STYLE2D_KEYS)
        .filter(({ layerType }) => layerType.includes(targetLayerType))
        .map(({ key, defaultValue, dataType, attrs, options = [] }) => ({
          editable: true,
          style2dKey: key,
          propertyKey: SYSTEM_PREFIX_PROPS && key.replace(SYSTEM_PREFIX_PROPS, '#'),
          value: defaultValue,
          options: options.map(o => ({
            ...o,
            text: this.$t(o.text)
          })),
          dataType,
          attrs
        }))
    },
    close() {
      this.dialog = false
    },
    submit() {
      this.isLoading = true
      // try catch 是為了讓exception可以結束loading
      try {
        const oldData = this.fileNode.fileContent
        const newProperties = Object.fromEntries(
          this.properties.map(({ style2dKey, propertyKey, value }) => [
            style2dKey || propertyKey,
            value
          ])
        )
        const newData = {
          ...oldData,
          features: oldData.features.map(feature => {
          // multi系列的geometry在features裡面只是片段
          // 要用切片之前的geometry

            return {
              ...feature,
              properties: {
                ...feature.properties,
                ...newProperties
              }
            }
          })
        }

        this.map.getSource(this.layerNode.uuid).setData(newData)
        this.fileNode.setFileContent(newData)
        return this.$store.dispatch('files/updateMaplayerData', {
          project: this.project,
          fileNode: this.fileNode,
          jsonContent: newData
        })
          .then(() => {
            this.close()
          })
          .catch(error => {
            this.$store.dispatch('snackbar/showError', {
              content: error
            })
          })
          .finally(() => {
            this.isLoading = false
          })
      } catch (error) {
        this.isLoading = false
        this.$store.dispatch('snackbar/showError', {
          content: error
        })
        throw error
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.step-title {
  font-size: 1.125rem;
}

.property-table {
  min-height: 100%;

  ::v-deep .v-data-table-header {
    tr th {
      font-size: 1rem;
      font-weight: bold;
      color: #343843;
    }
  }

  ::v-deep .property-tr {
    &:nth-of-type(odd) {
      background-color: #f0f3f6;
    }

    td {
      max-width: 120px;
      font-size: 1rem;
      overflow-wrap: break-word;

      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
  }
}

.dashboard-card {
  border: 1px solid $color-primary;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);

  .dashboard-description {
    @include multi-line-trancate(2);
  }
}

.v-btn.v-btn.action-btn {
  padding: 0.286em 1.743em;
  height: unset;
  font-size: 0.875rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;
}

.v-card__actions {
  display: flex;
  justify-content: flex-end;
}
</style>
