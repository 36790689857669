<template>
  <div>
    <slot
      v-if="!hasData"
      name="activator"
      :on="vOn"
      :loading="isLoading"
    >
      <v-btn
        :loading="isLoading"
        v-on="vOn"
      >
        {{ $t('massbalance_chart') }}
      </v-btn>
    </slot>
    <MassbalanceChartDialog
      v-else
      v-model="dialog"
      :mb-data="mbData"
    >
      <template v-slot:activator="{ on, attrs }">
        <slot name="activator" :on="on" :attrs="attrs" :loading="isLoading">
          <v-btn
            v-bind="attrs"
            :loading="isLoading"
            v-on="on"
          >
            {{ $t('massbalance_chart') }}
          </v-btn>
        </slot>
      </template>
    </MassbalanceChartDialog>
  </div>
</template>

<script>
import MassbalanceChartDialog from './MassbalanceChartDialog.vue'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'MassbalanceChart',

  components: {
    MassbalanceChartDialog
  },

  data: () => ({
    dialog: false
  }),

  computed: {
    ...mapState({
      currentLayerNode: state => state.map.currentLayerNode
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute'
    }),

    vOn() {
      return { click: this.fetchMbData }
    },
    project() {
      return this.getProjectByRoute(this.$route)
    },
    mbData() {
      return this.currentLayerNode?.mbDatFileNode?.fileContent
    },
    hasData() {
      return !!this.mbData
    },
    isLoading() {
      if (!this.currentLayerNode?.mbDatFileNode) {
        if (!this.currentLayerNode) {
          return false
        }

        return this.currentLayerNode.isCreatingMbDatFileNode
      }

      return (
        this.currentLayerNode.isCreatingMbDatFileNode ||
          this.currentLayerNode.mbDatFileNode.isLoading
      )
    }
  },

  methods: {
    async fetchMbData() {
      if (!this.currentLayerNode) {
        return
      }

      if (!this.currentLayerNode.mbDatFileNode) {
        this.currentLayerNode.setProperties({
          isCreatingMbDatFileNode: true
        })
        const mbDatFileNode = await this.$store.dispatch('globalFiles/fetchFileSubTree', {
          project: this.project,
          fileUuid: this.currentLayerNode.settings?.massbalance?.datMaplayerResourceUuid
        })

        if (!mbDatFileNode || mbDatFileNode instanceof Error) {
          this.$store.dispatch('snackbar/showError', {
            content: mbDatFileNode
          })

          this.currentLayerNode.setProperties({
            isCreatingMbDatFileNode: false
          })

          return
        }

        this.currentLayerNode.setProperties({
          mbDatFileNode
        })
      }

      return this.$store.dispatch('globalFiles/fetchFileContent', {
        project: this.project,
        fileNode: this.currentLayerNode.mbDatFileNode
      })
        .then(() => {
          this.dialog = true
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
        .finally(() => {
          this.currentLayerNode.setProperties({
            isCreatingMbDatFileNode: false
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
